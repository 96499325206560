var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{ref:"modal-sosmed",attrs:{"id":"modal-sosmed","centered":"","size":"sm","hide-footer":true,"hide-header":true,"no-close-on-backdrop":""},on:{"shown":function($event){return _vm.showModal()}}},[_c('div',{staticClass:"content-modal"},[_c('div',{staticClass:"image-content"},[_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/warning.svg","alt":"","width":"100px"}})]),_c('div',{staticClass:"title-content"},[_c('span',[_vm._v("Tampilkan media sosial?")])]),_c('div',{staticClass:"list-content-sosmed"},[_c('div',{staticClass:"sosmed-list"},[_c('div',{staticClass:"subtitle-content"},[_c('span',[_vm._v(" Pilih media sosial yang akan ditampilkan ")])]),_c('div',{staticClass:"sosmed-list-item isFacebook mt-[12px]"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"image-list mr-[10px]"},[(_vm.data.fb)?_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Facebook-True.svg","alt":"icon fb"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Facebook-False.svg","alt":"icon fb"}})]),_c('div',{class:_vm.data.fb !== ''
                  ? 'username-list text-[#222]'
                  : 'username-list text-[#C2C2C2]'},[_vm._v(" "+_vm._s(_vm.data.fb || 'Tidak ada data')+" ")])]),_c('div',{staticClass:"togle-list"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.data.fb === ''},on:{"change":_vm.handleSwitch},model:{value:(_vm.isFacebook),callback:function ($$v) {_vm.isFacebook=$$v},expression:"isFacebook"}})],1)]),_c('div',{staticClass:"sosmed-list-item isTikTok mt-[12px]"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"image-list mr-[10px]"},[(_vm.data.tk)?_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Tiktok-True.svg","alt":"icon tk"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Tiktok-False.svg","alt":"icon tk"}})]),_c('div',{class:_vm.data.tk !== ''
                  ? 'username-list text-[#222]'
                  : 'username-list text-[#C2C2C2]'},[_vm._v(" "+_vm._s(_vm.data.tk || 'Tidak ada data')+" ")])]),_c('div',{staticClass:"togle-list"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.data.tk === ''},on:{"change":_vm.handleSwitch},model:{value:(_vm.isTikTok),callback:function ($$v) {_vm.isTikTok=$$v},expression:"isTikTok"}})],1)]),_c('div',{staticClass:"sosmed-list-item isInstagram mt-[12px]"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"image-list mr-[10px]"},[(_vm.data.ig)?_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Instagram-True.svg","alt":"icon ig"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Instagram-False.svg","alt":"icon ig"}})]),_c('div',{class:_vm.data.ig !== ''
                  ? 'username-list text-[#222]'
                  : 'username-list text-[#C2C2C2]'},[_vm._v(" "+_vm._s(_vm.data.ig || 'Tidak ada data')+" ")])]),_c('div',{staticClass:"togle-list"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.data.ig === ''},on:{"change":_vm.handleSwitch},model:{value:(_vm.isInstagram),callback:function ($$v) {_vm.isInstagram=$$v},expression:"isInstagram"}})],1)]),_c('div',{staticClass:"sosmed-list-item isTwitter mt-[12px]"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"image-list mr-[10px]"},[(_vm.data.tw)?_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Tweeter-True.svg","alt":"icon tw"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Tweeter-False.svg","alt":"icon tw"}})]),_c('div',{class:_vm.data.tw !== ''
                  ? 'username-list text-[#222]'
                  : 'username-list text-[#C2C2C2]'},[_vm._v(" "+_vm._s(_vm.data.tw || 'Tidak ada data')+" ")])]),_c('div',{staticClass:"togle-list"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.data.tw === ''},on:{"change":_vm.handleSwitch},model:{value:(_vm.isTwitter),callback:function ($$v) {_vm.isTwitter=$$v},expression:"isTwitter"}})],1)]),_c('div',{staticClass:"sosmed-list-item isTelegram mt-[12px]"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"image-list mr-[10px]"},[(_vm.data.tg)?_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Telegram-True.svg","alt":"icon tg"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/icons/Telegram-False.svg","alt":"icon tg"}})]),_c('div',{class:_vm.data.tg !== ''
                  ? 'username-list text-[#222]'
                  : 'username-list text-[#C2C2C2]'},[_vm._v(" "+_vm._s(_vm.data.tg || 'Tidak ada data')+" ")])]),_c('div',{staticClass:"togle-list"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.data.tg === ''},on:{"change":_vm.handleSwitch},model:{value:(_vm.isTelegram),callback:function ($$v) {_vm.isTelegram=$$v},expression:"isTelegram"}})],1)])]),_c('div',{staticClass:"button-sosmed"},[_c('BButton',{staticClass:"w-[150px]",attrs:{"variant":"outline-primary"},on:{"click":_vm.closeModal}},[_vm._v(" Batal ")]),_c('BButton',{staticClass:"w-[150px]",attrs:{"variant":"primary"},on:{"click":_vm.updateData}},[_vm._v(" Tampilkan ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }