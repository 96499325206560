<template>
  <BModal
    id="modal-delete"
    ref="modal-delete"
    centered
    size="sm"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div class="text-center">
      <p class="text-[#333] my-2">
        <b class="text-2xl">Hapus Data</b>
      </p>
      <p class="text-[#333] my-2">
        Anda yakin untuk menghapus data
        <b>{{ data.full_name }}</b> dari pendamping UMKM
      </p>
    </div>
    <BRow>
      <BCol lg="6">
        <b-button
          variant="outline-primary"
          block
          class="mb-1"
          @click="cancelDelete"
        >
          Batal
        </b-button>
      </BCol>
      <BCol lg="6">
        <b-button
          variant="primary"
          block
          class="mb-1"
          @click="confirmDelete"
        >
          Hapus
        </b-button>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    getDataPumkm: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    cancelDelete() {
      this.$bvModal.hide('modal-delete')
    },
    confirmDelete() {
      this.$bvModal.hide('modal-delete')
      this.loading = true
      const params = this.data.id_pumkm
      const url = `/v1/pumkm/admin/removal-status-pumkm/${params}`
      affiliateAxiosIns.delete(url).then(res => {
        this.loading = false
        this.$toast_success({ message: 'Data PUMKM Berhasil Dihapus' })
        this.getDataPumkm()
      }).catch(err => {
        this.$toast_error({ message: 'Data PUMKM Gagal Dihapus' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Index.scss';
</style>
