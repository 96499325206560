<template>
  <div class="Card-pumkm">
    <BCard class="mt-1">
      <strong
        class="text-black text-bold text-xl"
      >Performa Pertumbuhan User (Partner)</strong>
      <BRow class="mt-1">
        <BCol
          lg="4"
          class=""
        >
          <BCard
            class="cardRegisterUmkm"
            body-class="flex flex-col justify-between class-body"
          >
            <div class="flex items-center mb-[5px]">
              <div class="text-black text-lg">
                PUMKM
              </div>
              <img
                id="popoverService1"
                src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
              >
              <BPopover
                id="popover-filter-type"
                target="popoverService1"
                placement="top"
                class="p-0"
                triggers="hover"
              >
                <span class="text-sm">Total Pendamping UMKM terdaftar</span>
              </BPopover>
            </div>
            <div class="text-black font-semibold">
              {{ data.count_registered || 0 }}
            </div>
          </BCard>
        </BCol>
        <BCol
          lg="4"
          class=""
        >
          <BCard
            class="cardGetMember"
            body-class="flex flex-col justify-between class-body"
          >
            <div class="flex items-center mb-[5px]">
              <div class="text-black text-lg">
                Get Member (Partner)
              </div>
              <img
                id="popoverService2"
                src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
              >
              <BPopover
                id="popover-filter-type"
                target="popoverService2"
                triggers="hover"
                placement="top"
                class="p-0"
              >
                <span class="text-sm">Pendamping UMKM yang sudah mendapatkan member/partner</span>
              </BPopover>
            </div>
            <div class="text-black font-semibold">
              {{ data.count_get_member || 0 }}
            </div>
          </BCard>
        </BCol>
        <BCol
          lg="4"
          class=""
        >
          <BCard
            class="cardGetCommision"
            body-class="flex flex-col justify-between class-body"
          >
            <div class="flex items-center mb-[5px]">
              <div class="text-black text-lg">
                Get Commision
              </div>
              <img
                id="popoverService3"
                src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
              >
              <BPopover
                id="popover-filter-type"
                target="popoverService3"
                triggers="hover"
                placement="top"
                class="p-0"
              >
                <span class="text-sm">Pendamping UMKM yang sudah mendapatkan komisi</span>
              </BPopover>
            </div>
            <div class="text-black font-semibold">
              {{ data.count_get_commission || 0 }}
            </div>
          </BCard>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Index.scss';
</style>
