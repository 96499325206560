<template>
  <BModal
    id="modal-linkpumkm"
    ref="modal-linkpumkm"
    centered
    size="sm"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
  >
    <div class="text-center py-2">
      <b class="text-2xl text-[#333]">Link Pendamping UMKM</b>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <b-avatar
        :src="data.photo"
        size="6rem"
      />
    </div>
    <div class="text-center mb-2">
      <b class="text-[#333]">{{ data.full_name }}</b>
    </div>
    <!-- <BFormInput
      readonly
      class="text-primary"
      :value="data.link_pumkm || '-'"
    /> -->
    <BInputGroup class="mb-1">
      <template #append>
        <b-input-group-text>
          <span
            :style="data.link_pumkm === '' || !isLinkPumkm ? 'border: none; color:#C2C2C2' : 'color:#F95031'"
          >Link</span>
        </b-input-group-text>
      </template>
      <BFormInput
        id="input-small"
        readonly
        :value="data.link_pumkm || '-'"
        class="form-control-link text-primary"
        @input="validateLinkPumkm"
      />
    </BInputGroup>
    <div class="mt-2">
      <b-button
        variant="primary"
        block
        class="mb-1"
        @click="hideModalLink"
      >
        Kembali
      </b-button>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLinkPumkm: true,
    }
  },
  methods: {
    hideModalLink() {
      this.$bvModal.hide('modal-linkpumkm')
    },
    validateLinkPumkm() {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/
      this.isLinkPumkm = urlPattern.test(this.data.link_pumkm)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Index.scss';
</style>
