<template>
  <BModal
    id="modal-sosmed"
    ref="modal-sosmed"
    centered
    size="sm"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
    @shown="showModal()"
  >
    <div class="content-modal">
      <div class="image-content">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/warning.svg"
          alt=""
          width="100px"
        >
      </div>
      <div class="title-content">
        <span>Tampilkan media sosial?</span>
      </div>
      <div class="list-content-sosmed">
        <div class="sosmed-list">
          <div class="subtitle-content">
            <span>
              Pilih media sosial yang akan ditampilkan
            </span>
          </div>
          <div class="sosmed-list-item isFacebook mt-[12px]">
            <div class="flex items-center">
              <div class="image-list mr-[10px]">
                <img
                  v-if="data.fb"
                  src="https://storage.googleapis.com/komerce/assets/icons/Facebook-True.svg"
                  alt="icon fb"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/Facebook-False.svg"
                  alt="icon fb"
                >
              </div>
              <div
                :class="
                  data.fb !== ''
                    ? 'username-list text-[#222]'
                    : 'username-list text-[#C2C2C2]'
                "
              >
                {{ data.fb || 'Tidak ada data' }}
              </div>
            </div>
            <div class="togle-list">
              <b-form-checkbox
                v-model="isFacebook"
                name="check-button"
                switch
                :disabled="data.fb === ''"
                @change="handleSwitch"
              />
            </div>
          </div>
          <div class="sosmed-list-item isTikTok mt-[12px]">
            <div class="flex items-center">
              <div class="image-list mr-[10px]">
                <img
                  v-if="data.tk"
                  src="https://storage.googleapis.com/komerce/assets/icons/Tiktok-True.svg"
                  alt="icon tk"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/Tiktok-False.svg"
                  alt="icon tk"
                >
              </div>
              <div
                :class="
                  data.tk !== ''
                    ? 'username-list text-[#222]'
                    : 'username-list text-[#C2C2C2]'
                "
              >
                {{ data.tk || 'Tidak ada data' }}
              </div>
            </div>
            <div class="togle-list">
              <b-form-checkbox
                v-model="isTikTok"
                name="check-button"
                switch
                :disabled="data.tk === ''"
                @change="handleSwitch"
              />
            </div>
          </div>
          <div class="sosmed-list-item isInstagram mt-[12px]">
            <div class="flex items-center">
              <div class="image-list mr-[10px]">
                <img
                  v-if="data.ig"
                  src="https://storage.googleapis.com/komerce/assets/icons/Instagram-True.svg"
                  alt="icon ig"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/Instagram-False.svg"
                  alt="icon ig"
                >
              </div>
              <div
                :class="
                  data.ig !== ''
                    ? 'username-list text-[#222]'
                    : 'username-list text-[#C2C2C2]'
                "
              >
                {{ data.ig || 'Tidak ada data' }}
              </div>
            </div>
            <div class="togle-list">
              <b-form-checkbox
                v-model="isInstagram"
                name="check-button"
                switch
                :disabled="data.ig === ''"
                @change="handleSwitch"
              />
            </div>
          </div>
          <div class="sosmed-list-item isTwitter mt-[12px]">
            <div class="flex items-center">
              <div class="image-list mr-[10px]">
                <img
                  v-if="data.tw"
                  src="https://storage.googleapis.com/komerce/assets/icons/Tweeter-True.svg"
                  alt="icon tw"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/Tweeter-False.svg"
                  alt="icon tw"
                >
              </div>
              <div
                :class="
                  data.tw !== ''
                    ? 'username-list text-[#222]'
                    : 'username-list text-[#C2C2C2]'
                "
              >
                {{ data.tw || 'Tidak ada data' }}
              </div>
            </div>
            <div class="togle-list">
              <b-form-checkbox
                v-model="isTwitter"
                name="check-button"
                switch
                :disabled="data.tw === ''"
                @change="handleSwitch"
              />
            </div>
          </div>
          <div class="sosmed-list-item isTelegram mt-[12px]">
            <div class="flex items-center">
              <div class="image-list mr-[10px]">
                <img
                  v-if="data.tg"
                  src="https://storage.googleapis.com/komerce/assets/icons/Telegram-True.svg"
                  alt="icon tg"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/Telegram-False.svg"
                  alt="icon tg"
                >
              </div>
              <div
                :class="
                  data.tg !== ''
                    ? 'username-list text-[#222]'
                    : 'username-list text-[#C2C2C2]'
                "
              >
                {{ data.tg || 'Tidak ada data' }}
              </div>
            </div>
            <div class="togle-list">
              <b-form-checkbox
                v-model="isTelegram"
                name="check-button"
                switch
                :disabled="data.tg === ''"
                @change="handleSwitch"
              />
            </div>
          </div>
        </div>
        <div class="button-sosmed">
          <BButton
            class="w-[150px]"
            variant="outline-primary"
            @click="closeModal"
          >
            Batal
          </BButton>
          <BButton
            class="w-[150px]"
            variant="primary"
            @click="updateData"
          >
            Tampilkan
          </BButton>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    facebook: {
      type: Number,
      default: () => 0,
    },
    tiktok: {
      type: Number,
      default: () => 0,
    },
    instagram: {
      type: Number,
      default: () => 0,
    },
    twitter: {
      type: Number,
      default: () => 0,
    },
    telegram: {
      type: Number,
      default: () => 0,
    },
    getDataPumkm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      alertError,
      alertSuccess,
      isFacebook: false,
      isTikTok: false,
      isTelegram: false,
      isInstagram: false,
      isTwitter: false,
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-sosmed')
      this.getDataPumkm()
    },
    updateData() {
      const url = '/v1/pumkm/admin/update-status-sosmed'
      const params = {
        id_pumkm: this.data.id_pumkm,
        fb_status: this.data.fb_status,
        tw_status: this.data.tw_status,
        ig_status: this.data.ig_status,
        tg_status: this.data.tg_status,
        tk_status: this.data.tk_status,
      }
      affiliateAxiosIns
        .put(url, params)
        .then(res => {
          this.$bvModal.hide('modal-sosmed')
          this.getDataPumkm()
          this.$toast_success({ message: 'Sosial Media Berhasil Ditampilkan' })
        })
        .catch(() => {
          this.$bvModal.hide('modal-sosmed')
          this.getDataPumkm()
          this.$toast_error({ message: 'Sosial Media Gagal Ditampilkan' })
        })
    },
    handleSwitch() {
      if (this.isFacebook === true) {
        this.data.fb_status = 1
      } else if (this.isFacebook === false) {
        this.data.fb_status = 0
      }
      if (this.isTikTok === true) {
        this.data.tk_status = 1
      } else if (this.isTikTok === false) {
        this.data.tk_status = 0
      }
      if (this.isInstagram === true) {
        this.data.ig_status = 1
      } else if (this.isInstagram === false) {
        this.data.ig_status = 0
      }
      if (this.isTwitter === true) {
        this.data.tw_status = 1
      } else if (this.isTwitter === false) {
        this.data.tw_status = 0
      }
      if (this.isTelegram === true) {
        this.data.tg_status = 1
      } else if (this.isTelegram === false) {
        this.data.tg_status = 0
      }
    },
    showModal() {
      this.isFacebook = this.facebook === 1
      this.isTikTok = this.tiktok === 1
      this.isInstagram = this.instagram === 1
      this.isTwitter = this.twitter === 1
      this.isTelegram = this.telegram === 1
    },
  },
}
</script>

<style scoped lang="css">
.content-modal {
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  text-align: center;
  gap: 16px;
}

.image-content {
  text-align: -webkit-center;
}

.title-content {
  color: var(--grayscale-main-black, #333);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.list-content-sosmed {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.subtitle-content {
  color: var(--grayscale-main-black, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sosmed-list .sosmed-list-item {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grayscale-off-white, #fff);
  box-shadow: 0px 0px 12px 0px rgba(24, 119, 242, 0.24);
}

.button-sosmed {
  display: flex;
  justify-content: space-between;
}
</style>
