<template>
  <BModal
    id="modal-skill"
    ref="modal-skill"
    centered
    size="sm"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
  >
    <div class="text-center py-2">
      <b class="text-2xl text-[#333]">Keahlian</b>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <b-avatar
        :src="data.photo"
        size="6rem"
      />
    </div>
    <div class="text-center mb-2">
      <b class="text-[#333]">{{ data.full_name }}</b>
    </div>
    <!-- <div class="text-center value-skill">
      <span class="text-[#333]">
        {{ data.skill || '-' }}
      </span>
    </div> -->
    <BFormInput
      readonly
      class="text-center form-control-link"
      :value="data.skill || '-'"
    />
    <div class="text-center py-2">
      <b-button
        block
        variant="primary"
        @click="hideDetailSkill"
      >
        Kembali
      </b-button>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    hideDetailSkill() {
      this.$bvModal.hide('modal-skill')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Index.scss';
</style>
