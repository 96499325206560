<template>
  <div>
    <strong class="text-black text-bold text-2xl">Dashboard</strong>
    <div>
      <CardRegisterPUMKM :data="data" />
      <ListDataPUMKM
        :data="listPumkm"
        :loading="loading"
        :get-data-pumkm="getDataPumkm"
        @handlePage="showPage"
      />
    </div>
  </div>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import CardRegisterPUMKM from './Card-pumkm.vue'
import ListDataPUMKM from './List-pumkm.vue'

export default {
  components: {
    CardRegisterPUMKM,
    ListDataPUMKM,
  },
  data() {
    return {
      data: {},
      loading: false,
      listPumkm: [],
      totalPerPage: 20,
      currentPage: 1,
      serviceName: 'all',
    }
  },
  mounted() {
    this.getDataPumkm()
  },
  methods: {
    async getDataPumkm() {
      this.loading = true
      const params = {
        per_page: this.totalPerPage,
        page: this.currentPage,
        service_name: this.serviceName,
      }
      const url = '/v1/pumkm/admin/pumkm-data'
      await affiliateAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.data = data
          this.listPumkm = data.list_pumkm
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    showPage(page) {
      this.totalPerPage = page
      this.getDataPumkm()
    },
  },
}
</script>
<style lang="scss" scoped></style>
